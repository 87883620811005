import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { io } from 'socket.io-client'

import config from '~/config'

import EndStreamControl from './middlewares/end-stream'
import Interactions from './middlewares/interactions'
import MessageReaction from './middlewares/message-reaction'
import MicPermission from './middlewares/participant-mic-permision'
import Plans from './middlewares/plans'
// import MessageReaction from './middlewares/message-reaction'
import RaiseHand from './middlewares/raise-hand'
import Remote from './middlewares/remote'
import RevealProduct from './middlewares/reveal-product'
import Signals from './middlewares/signals'
import StreamsHistory from './middlewares/streams-history'
import TokenSecurity from './middlewares/token-security'
import ViewersRoom from './middlewares/viewers-room'

export type SocketContextMiddlewaresTypes = {
  tokenSecurity: TokenSecurity
  micPermission: MicPermission
  endStreamControl: EndStreamControl
  viewersRoom: ViewersRoom
  messageReaction?: MessageReaction
  raiseHand: RaiseHand
  revealProduct: RevealProduct
  remote?: Remote
  interactions: Interactions
  plans: Plans
  streamsHistory: StreamsHistory
  signals: Signals
}
const SocketContextInitialValues = {
  tokenSecurity: null,
  micPermission: null,
  endStreamControl: null,
  viewersRoom: null,
  // messageReaction:  MessageReaction,
  raiseHand: null,
  remote: null,
  revealProduct: null,
  interactions: null,
  plans: null,
  streamsHistory: null,
  Signals: null,
}

const SocketContext = createContext(SocketContextInitialValues)
const { Provider } = SocketContext
const ConnectSocket = ({
  token,
}: {
  token: string
}): SocketContextMiddlewaresTypes => {
  const socket = useRef<any>(null)
  const instanceRemoteInterface = config.app.remote.enabled
  const { pathname, components }: any = useRouter()

  const currentLocation = components?.[pathname]?.Component?.name

  const middlewares = useMemo(() => {
    const middlewaresObject: SocketContextMiddlewaresTypes = {
      tokenSecurity: new TokenSecurity(),
      micPermission: new MicPermission(),
      endStreamControl: new EndStreamControl(),
      viewersRoom: new ViewersRoom(),
      // messageReaction: new MessageReaction(),
      raiseHand: new RaiseHand(),
      revealProduct: new RevealProduct(),
      interactions: new Interactions(),
      plans: new Plans(),
      streamsHistory: new StreamsHistory(),
      signals: new Signals(),
    }
    if (instanceRemoteInterface) {
      middlewaresObject.remote = new Remote()
    }
    return middlewaresObject
  }, [instanceRemoteInterface])

  /* eslint-disable no-console */
  useEffect(() => {
    if (!token) {
      return undefined
    }

    socket.current = io(config.events.url, {
      transports: ['websocket'],
      upgrade: false,
      reconnection: true,
      auth: {
        token,
        currentLocation,
      },
    })
    // console.log is used to debug websocket connection
    socket.current.on('connect', () => {
      Object.values(middlewares).forEach((m: any) =>
        m.setSocket(socket.current),
      )

      const { engine } = socket.current.io
      console.log('[Websocket] transport name: ', engine.transport.name)

      engine.once('upgrade', () => {
        // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
        console.log('[Websocket] transport name: ', engine.transport.name)
      })

      engine.on('packet', ({ type, data }: any) => {
        // called for each packet received
        console.log('[Websocket] packet: ', type, data)
      })

      engine.on('packetCreate', ({ type, data }: any) => {
        // called for each packet sent
        console.log('[Websocket] packetCreate: ', type, data)
      })

      engine.on('drain', () => {
        // called when the write buffer is drained
        console.log('[Websocket] drain')
      })

      engine.on('close', (reason: string) => {
        // called when the underlying connection is closed
        console.log('[Websocket] close: ', reason)
      })
    })

    socket.current.on('disconnect', () => {
      Object.values(middlewares).forEach((m: any) => m.unsetSocket())
      console.log('[Websocket] disconnected')
    })

    socket.current.on('reconnect', () => {
      console.log('[Websocket] reconnecting')
    })

    return () => {
      socket.current.disconnect()
    }
  }, [token])
  /* eslint-disable no-console */

  return {
    ...middlewares,
  }
}

export const useSocketContext = (): SocketContextMiddlewaresTypes =>
  useContext(SocketContext) as any

export const SocketContextProvider = ({
  token,
  children,
}: {
  token: string
  children: React.ReactNode
}) => (
  <Provider
    value={
      ConnectSocket({
        token,
      }) as any
    }
  >
    {children}
  </Provider>
)
