class DeferredInitialisation {
  deferredInitialisation: any = null

  setInitialised: any = null

  setInitialisationError: any = null

  constructor() {
    this.deferredInitialisation = new Promise((resolve, reject) => {
      this.setInitialised = resolve
      this.setInitialisationError = reject
    })
  }

  isReady = (cb: any) => this.deferredInitialisation.then(cb)
}

export default DeferredInitialisation
