import { useRouter } from 'next/router'

import EventTitle from './EventTitle'
import StageTitle from './StageTitle'

const PagesTitle = () => {
  const router = useRouter()
  /**
    those are the ticket office routes
   */
  if (router.asPath.includes('ticket-office')) {
    return <EventTitle />
  }
  return <StageTitle />
}

export default PagesTitle
