import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  GET_STREAMS_HISTORY: 'get-streams-history',
  STREAMS_HISTORY_RECEIVED: 'streams-history-received',
  HANDLE_SPOTLIGHT_STREAM: 'handle-spotlight-stream',
  HANDLE_PINWIDE_STREAM: 'handle-pinwide-stream',
}

export interface StreamsHistoryType {
  spotlightStreams: string[]
  pinwideStream: string
}

class StreamsHistory extends DeferredInitialisation {
  socket: Socket | null = null

  onStreamHistoryReceivedCallback: any

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket?.on(
      messageTypes.STREAMS_HISTORY_RECEIVED,
      this.handleStreamsHistoryReceived,
    )

    this.setInitialised(this)
  }

  unsetSocket = () => {
    this.socket?.off(
      messageTypes.STREAMS_HISTORY_RECEIVED,
      this.handleStreamsHistoryReceived,
    )

    this.socket = null
  }

  handleGetStreamsHistory(stageId: string) {
    this.socket?.emit(messageTypes.GET_STREAMS_HISTORY, { stageId })
  }

  handleStreamsHistoryReceived = (data: StreamsHistoryType) => {
    this?.onStreamHistoryReceivedCallback?.(data)
  }

  handleSpotlightStream(streamId: string, stageId: string) {
    this.socket?.emit(messageTypes.HANDLE_SPOTLIGHT_STREAM, {
      streamId,
      stageId,
    })
  }

  handlePinwideStream(streamId: string, stageId: string) {
    this.socket?.emit(messageTypes.HANDLE_PINWIDE_STREAM, {
      streamId,
      stageId,
    })
  }
}

export default StreamsHistory
