// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw'

import { api } from '../utils'

const handlers = [
  rest.post(
    api('/v1/group/:groupId/handle-pinwide-history/:streamId'),
    (_req, res, ctx) => {
      res(ctx.status(200))
    },
  ),
  rest.post(
    api('/v1/group/:groupId/handle-spotlight-history/:streamId'),
    (_req, res, ctx) => {
      res(ctx.status(200))
    },
  ),
]

export default handlers
