import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  REQUEST_PERMISSION: 'request-permission',
  CANCEL_REQUEST: 'cancel-request',
  ACCEPT_PERMISSION: 'accept-permission',
  REJECT_PERMISSION: 'reject-permission',
}

class MicPermission extends DeferredInitialisation {
  socket: Socket | null = null

  onRequestedPermissionCallback: any = null

  onCancelledRequestCallback: any = null

  onAcceptedPermissionCallback: any = null

  onRejectedPermissionCallback: any = null

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket.on(messageTypes.REQUEST_PERMISSION, this.onRequestedPermission)
    this.socket.on(messageTypes.CANCEL_REQUEST, this.onCancelledRequest)
    this.socket.on(messageTypes.ACCEPT_PERMISSION, this.onAcceptedPermission)
    this.socket.on(messageTypes.REJECT_PERMISSION, this.onRejectedPermission)

    // Check DeferredInitialisation for more details.
    this.setInitialised(this)
  }

  unsetSocket = () => {
    this?.socket?.off(
      messageTypes.REQUEST_PERMISSION,
      this.onRequestedPermission,
    )
    this?.socket?.off(messageTypes.CANCEL_REQUEST, this.onCancelledRequest)
    this?.socket?.off(messageTypes.ACCEPT_PERMISSION, this.onAcceptedPermission)
    this?.socket?.off(messageTypes.REJECT_PERMISSION, this.onRejectedPermission)

    this.socket = null
  }

  onRequestedPermission = (message: {
    userId: string
    userName: string
    socketId: string
  }) => {
    const { userId, userName, socketId } = message

    const accept = () => {
      this.socket?.emit(messageTypes.ACCEPT_PERMISSION, {
        userId,
        socketId,
      })
    }

    const reject = () => {
      this.socket?.emit(messageTypes.REJECT_PERMISSION, {
        userId,
        socketId,
      })
    }

    this.onRequestedPermissionCallback?.({
      userId,
      userName,
      accept,
      reject,
    })
  }

  onCancelledRequest = (userId: string) => {
    this.onCancelledRequestCallback?.(userId)
  }

  onAcceptedPermission = () => {
    this.onAcceptedPermissionCallback?.()
  }

  onRejectedPermission = () => {
    this.onRejectedPermissionCallback?.()
  }

  requestPermission = (userId: string, userName: string) => {
    this.socket?.emit(messageTypes.REQUEST_PERMISSION, {
      userId,
      userName,
    })
  }
}

export default MicPermission
