import styled, { css } from 'styled-components'

interface ColorCssProps {
  $type?: 'danger' | 'secondary' | 'success' | 'info'
}
const colorCSS = css<ColorCssProps>`
  ${(props) =>
    props.$type === 'danger' &&
    css`
      color: @danger-color;
    `}
  ${(props) =>
    props.$type === 'secondary' &&
    css`
      color: @text-color-secondary;
    `}
  ${(props) =>
    props.$type === 'success' &&
    css`
      color: @success-color;
    `}
  ${(props) =>
    props.$type === 'info' &&
    css`
      color: @info-color;
    `}
`

const sizes = {
  bigger: css`
    font-size: @font-size-bigger;
  `,
  regular: css`
    font-size: @font-size-base;
  `,
  smaller: css`
    font-size: @font-size-sm;
  `,
}

interface PProps extends ColorCssProps {
  $align?: string
  $size?: 'bigger' | 'regular' | 'smaller'
}

const P = styled.p<PProps>`
  ${(props) => (props.$type ? colorCSS : '')};
  ${(props) =>
    props.$align &&
    css`
    text-align: ${props.$align};
  `};
  ${(props) => sizes?.[props.$size ?? 'regular']};

  margin: 0;
`

export default P
