import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  BAN: 'ban',
  USER_BANNED: 'user-banned',
}

class Signals extends DeferredInitialisation {
  socket: Socket | null = null

  onUserBannedCallback: () => void = () => {}

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket?.on(messageTypes.USER_BANNED, this.onUserBanned)

    this.setInitialised(this)
  }

  unsetSocket = () => {
    this?.socket?.off(messageTypes.USER_BANNED, this.onUserBanned)

    this.socket = null
  }

  ban = (stageId: string, userId: string) => {
    this?.socket?.emit(messageTypes.BAN, { stageId, userId })
  }

  onUserBanned = () => {
    this.onUserBannedCallback()
  }
}

export default Signals
