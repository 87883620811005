import moment from 'moment'
// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw'

import { parseBoolean } from '~/config'

import { UserTypes } from '~/types/user'

import { api } from '../utils'
import { getUserStub, openTokTokenStub, userStub } from './stubs'

const handlers = [
  rest.get(api('/v1/users/me'), (_req, res, ctx) => {
    const token = _req.headers.get('authorization')
    if (!token) {
      return res(ctx.status(401))
    }
    const [role, type] = token.split('Bearer ')[1].split(':')
    const loggedUser = getUserStub(parseBoolean(role), type as UserTypes)

    if (token.includes('not-started')) {
      userStub.stages[0].stage.startDate = moment().add(3, 'hour').toDate()
    }

    return res(ctx.status(200), ctx.json(loggedUser))
  }),
  rest.post(api('/v1/users/cookies-consent'), (_req, res, ctx) => {
    userStub.cookiesConsent = true
    res(ctx.status(200), ctx.json({}))
  }),
  rest.post(api('/v1/users/agreed-rules'), (_req, res, ctx) =>
    res(ctx.status(200), ctx.json(true)),
  ),
  rest.post(api('/v1/users/generate-ot-token'), (_req, res, ctx) =>
    res(ctx.status(200), ctx.json(openTokTokenStub)),
  ),
  rest.delete(api('/v1/users/delete-message'), (_req, res, ctx) =>
    res(ctx.status(200), ctx.json('success')),
  ),
  rest.put(api('/v1/users/update-message'), (_req, res, ctx) =>
    res(ctx.status(200), ctx.json(true)),
  ),
  rest.post(api('/v1/users/:id/ban'), (_req, res, ctx) =>
    res(ctx.status(200), ctx.json('true')),
  ),
]

export default handlers
