import { useMemo } from 'react'

import { VideoCountDownContainer } from './components'

interface VideoCountDownProps {
  timeLeft: number
}
const VideoCountDown = ({ timeLeft }: VideoCountDownProps) => {
  const secondsLeft = useMemo(() => {
    const seconds = timeLeft % 60

    if (seconds <= 0) {
      return '00'
    }

    if (seconds < 10) {
      return `0${seconds}`
    }

    return seconds
  }, [timeLeft])

  const minutesLeft = useMemo(() => {
    const minutes = Math.floor(timeLeft / 60)

    if (minutes <= 0) {
      return '00'
    }

    if (minutes < 10) {
      return `0${minutes}`
    }

    return minutes
  }, [timeLeft])

  return (
    <VideoCountDownContainer>
      <span className="block">{minutesLeft}</span>
      {' : '}
      <span className="block">{secondsLeft}</span>
    </VideoCountDownContainer>
  )
}

export default VideoCountDown
