import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  RAISE_HAND: 'raise-hand',
}

class RaiseHand extends DeferredInitialisation {
  socket: Socket | null = null

  onUsersUpdateCallback: any = null

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket.on(messageTypes.RAISE_HAND, this.onUsersUpdate)

    this.setInitialised(this)
  }

  unsetSocket = () => {
    this?.socket?.off(messageTypes.RAISE_HAND, this.onUsersUpdate)

    this.socket = null
  }

  onUsersUpdate = ({
    userName,
    userId,
  }: {
    userName: string
    userId: string
  }) => {
    this.onUsersUpdateCallback?.(userName, userId)
  }

  setUsersUpdateCallback = (cb: any) => {
    this.onUsersUpdateCallback = cb
  }

  raiseHand(groupId: string) {
    this.socket?.emit(messageTypes.RAISE_HAND, { groupId })
  }
}

export default RaiseHand
