import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  JOIN_PAGE: 'join-page',
  NEW_USER_JOINED: 'new-user-joined',
  SPEED_UP_COUNTDOWN: 'speed-up-countdown',
  COUNTDOWN_SPEEDED_UP: 'countdown-speeded-up',
  RESET_COUNTDOWN: 'reset-countdown',
  COUNTDOWN_RESETED: 'countdown-reseted',
}

class Remote extends DeferredInitialisation {
  socket: Socket | null = null

  onUsersUpdateCallback: (list: any) => void = () => {}

  onCountdownSpeededUpCallback: () => void = () => {}

  onCountdownResetedCallback: () => void = () => {}

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket.on(messageTypes.NEW_USER_JOINED, this.onUsersUpdate)
    this.socket.on(messageTypes.COUNTDOWN_SPEEDED_UP, this.onCountdownSpeededUp)
    this.socket.on(messageTypes.COUNTDOWN_RESETED, this.onCountdownReseted)

    this.setInitialised(this)
  }

  unsetSocket = () => {
    this?.socket?.off(messageTypes.NEW_USER_JOINED, this.onUsersUpdate)
    this?.socket?.off(
      messageTypes.COUNTDOWN_SPEEDED_UP,
      this.onCountdownSpeededUp,
    )
    this?.socket?.off(messageTypes.COUNTDOWN_RESETED, this.onCountdownReseted)

    this.socket = null
  }

  onUsersUpdate = (list: any) => {
    this.onUsersUpdateCallback?.(list)
  }

  joinPage(currentLocation: any) {
    this.socket?.emit(messageTypes.JOIN_PAGE, { currentLocation })
  }

  resetCountdown(groupId: string) {
    this?.socket?.emit(messageTypes.RESET_COUNTDOWN, {
      groupId,
    })
  }

  speedUpCountdown(groupId: string) {
    this?.socket?.emit(messageTypes.SPEED_UP_COUNTDOWN, {
      groupId,
    })
  }

  onCountdownReseted = () => {
    this.onCountdownResetedCallback?.()
  }

  onCountdownSpeededUp = () => {
    this.onCountdownSpeededUpCallback?.()
  }
}

export default Remote
