import Image from 'next/image'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isIOS, isSafari } from 'react-device-detect'

import SpaceV2 from '@@/SpaceV2'

import {
  SoundButton,
  UnmuteText,
  VideoContainer,
  VideoElement,
} from './components'
import VideoCountDown from './VideoCountDown'

interface VideoSceneProps {
  onStart?: () => void
  onEnd?: () => void
  videoUrl: string
  videoDuration?: number
  loop?: boolean
  isUserInteracted?: boolean
}
const VideoScene = ({
  onStart,
  onEnd,
  videoUrl,
  videoDuration = 0,
  loop,
  isUserInteracted,
}: VideoSceneProps) => {
  const videoRef = useRef<any>()
  const [videoTimeLeft, videoTimeLeftSet] = useState(Math.ceil(videoDuration))
  const [muted, mutedSet] = useState(!isUserInteracted)

  const onTimeUpdate = useCallback(
    (e: { target: { currentTime: number } }) => {
      videoTimeLeftSet(Math.ceil(videoDuration - e.target.currentTime))
    },
    [videoDuration],
  )
  const handleVideoMuteState = useCallback(() => {
    if (videoRef?.current.muted) {
      mutedSet(true)
    } else {
      mutedSet(false)
    }
  }, [])
  const unmuteVideo = useCallback(() => {
    videoRef.current.muted = false
    mutedSet(false)
  }, [])

  useEffect(() => {
    if (!videoRef?.current) {
      return
    }

    const localVideoRef = videoRef?.current

    localVideoRef.addEventListener('play', onStart)
    localVideoRef.addEventListener('ended', onEnd)
    localVideoRef.addEventListener('timeupdate', onTimeUpdate)
    localVideoRef.addEventListener('volumechange', handleVideoMuteState)

    // eslint-disable-next-line consistent-return
    return () => {
      localVideoRef.removeEventListener('play', onStart)
      localVideoRef.removeEventListener('ended', onEnd)
      localVideoRef.removeEventListener('timeupdate', onTimeUpdate)
      localVideoRef.removeEventListener('volumechange', handleVideoMuteState)
    }
  }, [onStart, onEnd, videoDuration, onTimeUpdate, handleVideoMuteState])

  const shouldBeMuted = isSafari || isIOS || !isUserInteracted

  return (
    <>
      <VideoContainer>
        {videoDuration && <VideoCountDown timeLeft={videoTimeLeft} />}
        <VideoElement
          ref={videoRef}
          src={videoUrl}
          autoPlay
          muted={shouldBeMuted}
          loop={loop}
          playsInline
        />
        {(!isUserInteracted || muted) && (
          <SoundButton onClick={unmuteVideo}>
            <SpaceV2
              direction="vertical"
              align="center"
              size={0}
            >
              <Image
                src="/images/volume-mute-solid.svg"
                alt="unmute"
                width={80}
                height={80}
                layout="fixed"
              />
              <UnmuteText>Click to unmute</UnmuteText>
            </SpaceV2>
          </SoundButton>
        )}
      </VideoContainer>
    </>
  )
}

export default VideoScene
