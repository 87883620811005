import { message } from 'antd'
import is from 'is_js'
import { useCallback, useState } from 'react'

import { PermissionState } from '~/types/permission'

type UseDevicesReturnType = {
  previewCamera?: (deviceId: string, errorHandler: () => void) => void
  closeCameraPreview: () => void
  stream: MediaStream | null
  askForCameraAndMicPermission: () => Promise<string>
  getDevices: () => Promise<MediaDeviceInfo[]>
  isLoadingPreview: boolean
}

export const useDevices = (): UseDevicesReturnType => {
  const [stream, setStream] = useState<MediaStream | null>(null)
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false)
  const hasUserMedia = useCallback(
    () => !!(navigator?.mediaDevices && navigator?.mediaDevices?.getUserMedia),
    [],
  )
  const askForCameraAndMicPermission = useCallback(async () => {
    try {
      const perstream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      })
      perstream?.getTracks().forEach((track) => {
        track.stop()
      })

      return PermissionState.GRANTED
    } catch (error: any) {
      // Chrom, Opera and Edge will throw NotReadableError
      // Firefox will throw AbortError
      if (error.name === 'NotReadableError' || error.name === 'AbortError') {
        return PermissionState.ALREADY_IN_USE
      }

      return PermissionState.REFUSED
    }
  }, [])

  const getDevices = useCallback(async () => {
    let devices: MediaDeviceInfo[] = []

    if (hasUserMedia()) {
      if (is.firefox()) {
        try {
          // eslint-disable-next-line no-shadow
          const mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          })
          devices = await navigator.mediaDevices.enumerateDevices()
          mediaStream?.getTracks().forEach((track) => {
            track.stop()
          })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log({ error })
        }
      } else {
        devices = await navigator.mediaDevices.enumerateDevices()
      }
    } else {
      message.error('"getUserMedia" is not supported by your browser')
    }

    return devices
  }, [hasUserMedia])
  const closeCameraPreview = useCallback(async () => {
    stream?.getTracks().forEach((track: { stop: () => void }) => {
      track.stop()
    })
  }, [stream])

  const previewCamera = useCallback(
    async (deviceId: string, errorHandler: () => void) => {
      setIsLoadingPreview(true)

      if (hasUserMedia()) {
        if (!deviceId) {
          return
        }

        const constraints = {
          video: {
            deviceId,
          },
          audio: false,
        }

        try {
          const currentStream = await navigator.mediaDevices.getUserMedia(
            constraints,
          )
          setStream(currentStream)
          setIsLoadingPreview(false)
        } catch (error: any) {
          setIsLoadingPreview(false)

          if (error.name === 'NotAllowedError') {
            errorHandler()
          } else {
            // eslint-disable-next-line no-console
            console.log(
              'navigator.MediaDevices.getUserMedia error: ',
              error.message,
              error.name,
            )
          }
        }
      }
    },
    [hasUserMedia],
  )

  return {
    previewCamera,
    closeCameraPreview,
    stream,
    askForCameraAndMicPermission,
    getDevices,
    isLoadingPreview,
  }
}
