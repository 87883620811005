interface ThemeButtonsType {
  borderColor: string
  borderColorHover: string
}

interface ThemeEventType {
  bgColor: string
}

interface PanelType {
  radius: string
  enabled: boolean
  bgColor: string
  textColor: string
  linkColor: string
  position: string
  backDropFilter?: string
}

export interface ThemeType {
  radius: string
  bgColor: string
  textColor: string
  textColorInverted: string
  linkColor: string
  buttons: ThemeButtonsType
  event: ThemeEventType
  panel: PanelType
}

export const theme: Record<string, ThemeType> = {
  armani: {
    radius: '5px',
    bgColor: '#111010',
    textColor: '#111010',
    textColorInverted: 'white',
    linkColor: 'white',
    buttons: {
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
    event: {
      bgColor: '#111010',
    },
    panel: {
      radius: '10px',
      enabled: false,
      bgColor: '#111010',
      textColor: 'white',
      linkColor: 'white',
      position: 'middle',
    },
  },
  dark: {
    radius: '4px',
    bgColor: '#111010',
    textColor: 'white',
    textColorInverted: '#111010',
    linkColor: 'white',
    buttons: {
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
    event: {
      bgColor: '#111010',
    },
    panel: {
      radius: '10px',
      enabled: true,
      bgColor: '#111010',
      textColor: 'white',
      linkColor: 'white',
      position: 'left',
    },
  },
  lacoste: {
    radius: '4px',
    bgColor: '#111010',
    textColor: 'white',
    textColorInverted: '#111010',
    linkColor: 'white',
    buttons: {
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
    event: {
      bgColor: '#93AEBD',
    },
    panel: {
      radius: '10px',
      enabled: true,
      bgColor: '#111010',
      textColor: 'white',
      linkColor: 'white',
      position: 'left',
    },
  },
  newTicketOffice: {
    radius: '4px',
    bgColor: '#111010',
    textColor: 'white',
    textColorInverted: '#111010',
    linkColor: 'white',
    buttons: {
      borderColor: 'transparent',
      borderColorHover: 'transparent',
    },
    event: {
      bgColor: '#93AEBD',
    },
    panel: {
      radius: '10px',
      enabled: true,
      bgColor:
        'linear-gradient(117.54deg, rgba(255, 255, 255, 0.3375) -19.85%, rgba(255, 255, 255, 0.3375) -2.73%, rgba(255, 255, 255, 0.3) 14.39%, rgba(255, 255, 255, 0.3) 31.52%, rgba(255, 255, 255, 0.3) 48.64%, rgba(255, 255, 255, 0.3) 65.76%, rgba(255, 255, 255, 0.3) 82.88%, rgba(255, 255, 255, 0.3) 100%)',
      backDropFilter: '13px',
      textColor: '#343434',
      linkColor: 'white',
      position: 'left',
    },
  },
}
