// eslint-disable-next-line import/no-extraneous-dependencies
import { Property } from 'csstype'
import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

type VerticalItemProps = {
  $size: number | Record<BreakPoints, string | number>
}

type HorizontalItemProps = {
  $size: number | Record<BreakPoints, string | number>
}
interface ContainerProps {
  $direction?: string
  $align?: string
}

interface GetItemForDirectionProps {
  direction: string
  size: number | Record<BreakPoints, string | number>
  key?: number
  node: React.ReactNode
}

interface SpaceV2Props {
  size?: number | Record<BreakPoints, string | number>
  align?: AlignType
  direction?: DirectionType
  children: React.ReactNode
  style?: React.CSSProperties
}

type DirectionType = 'horizontal' | 'vertical'
type AlignType = 'start' | 'center' | 'end' | 'baseline' | 'stretch'
/**
 * TODO: Find a way to extract those values from
 * ./src/styles/ant-design.less
 */
export type BreakPoints =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | '4k'
  | string
const breakpoints: Record<string, string> = {
  /**
   * This is inspired from Ant Design.
   */
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1900px)',
  '4k': '(min-width: 2200px)',
}

const VerticalItem = styled.div<VerticalItemProps>`
  &:empty {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${({ $size }) => {
    if (Number.isInteger($size)) {
      // number
      return css`
        margin-bottom: ${$size}px;
      `
    }

    const responsiveCSS = Object.entries($size)
      .map(([key, size]) => {
        if (key === 'base') {
          return `
            margin-bottom: ${size}px;
          `
        }

        if (!Object.keys(breakpoints).includes(key)) {
          return `
            @media screen and (min-width: ${key}px) {
              margin-bottom: ${size}px;
            }
          `
        }

        const bpValue = breakpoints[key]

        return `
          @media screen and ${bpValue} {
            margin-bottom: ${size}px;
          }
        `
      })
      .join('')

    return css`
      ${responsiveCSS}
    `
  }}
`
const HorizontalItem = styled.div<HorizontalItemProps>`
  &:empty {
    display: none;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ $size }) => {
    if (Number.isInteger($size)) {
      // number
      return css`
        margin-right: ${$size}px;
      `
    }

    /**
     * TODO: Find a way to extract those values from
     * ./src/styles/ant-design.less
     */

    const responsiveCSS = Object.entries($size)
      .map(([key, size]) => {
        if (key === 'base') {
          return `
            margin-right: ${size}px;
          `
        }

        if (!Object.keys(breakpoints).includes(key)) {
          return `
            @media screen and (min-width: ${key}px) {
              margin-right: ${size}px;
            }
          `
        }

        const bpValue = breakpoints[key]

        return `
          @media screen and ${bpValue} {
            margin-right: ${size}px;
          }
        `
      })
      .join('')

    return css`
      ${responsiveCSS}
    `
  }}
`
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.$direction};

  ${(props) =>
    props.$direction === 'column' &&
    css`
    align-items: ${props.$align};
  `};

  ${(props) =>
    props.$direction === 'row' &&
    css`
    justify-content: ${props.$align};
  `};
`
const alignProps: Record<AlignType, Property.AlignItems> = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  baseline: 'baseline',
  stretch: 'stretch',
}

const directionProps: Record<DirectionType, Property.FlexDirection> = {
  horizontal: 'row',
  vertical: 'column',
}

const resolveChildren = (children: any): any => {
  if (Array.isArray(children)) {
    // Removes falsy elements (like condition leftovers)
    // which polute children and create empty elements
    return children
      .filter((child) => child)
      .flatMap((child) => child && resolveChildren(child))
  }

  return children.type === Fragment && Array.isArray(children.props.children)
    ? children.props.children.filter((child: React.ReactNode) => child)
    : children
}

const getItemForDirection = ({
  direction,
  size,
  key,
  node,
}: GetItemForDirectionProps) => {
  if (direction === 'vertical') {
    return (
      <VerticalItem
        key={key}
        $size={size}
      >
        {node}
      </VerticalItem>
    )
  }

  return (
    <HorizontalItem
      key={key}
      $size={size}
    >
      {node}
    </HorizontalItem>
  )
}

const SpaceV2 = ({
  size = 10,
  align,
  direction = 'horizontal',
  children,
  style,
}: SpaceV2Props) => {
  const directionValue = directionProps?.[direction] ?? 'row'
  const alignValue = alignProps?.[align!] ?? 'unset'

  /**
   * Unwrap Fragments so we can use them directly as a child.
   * Previously we couldn't do that:
   * <Space>
   *   {shouldDisplayLala && (
   *     <>
   *       <p>lala</p>
   *       <p>lala</p>
   *       <p>lala</p>
   *     </>
   *   )}
   * </Space>
   */

  const resolvedChildren = resolveChildren(children)

  return (
    <Container
      $direction={directionValue}
      $align={alignValue}
      style={style}
    >
      {resolvedChildren?.map?.((node: React.ReactNode, index: number) =>
        getItemForDirection({
          direction,
          node,
          key: index,
          size,
        }),
      )}
      {!Array.isArray(resolvedChildren) &&
        getItemForDirection({
          direction,
          node: children,
          size,
        })}
    </Container>
  )
}

export default SpaceV2
