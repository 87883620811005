import { api, useSWR } from '@coding4tomorrow/c4t-next-core'

const useProjectInfo = () => {
  const {
    data: projectInfos,
    isLoading,
    error,
    mutate: mutateProjectInfos,
  } = useSWR(api.url('project.getProjectPublicInfos'))

  return {
    ...projectInfos,
    isTicketOfficeActive: !!projectInfos?.ticketOffice?.isActive,
    isLoading,
    error,
    nonExistentProject: error?.status === 404 || !projectInfos,
    mutateProjectInfos,
  }
}

export default useProjectInfo
