import { Spin } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #020C16;

  .ant-spin-dot-item {
    background-color: white;
  }
`
interface FullPageLoaderProps {
  spinning?: boolean
  size?: 'small' | 'large' | 'default'
  delay?: number
}

const FullPageLoader = ({
  spinning = true,
  size = 'large',
  delay = 200,
  ...props
}: FullPageLoaderProps) => (
  <Container>
    <Spin
      spinning={spinning}
      size={size}
      delay={delay}
      {...props}
    />
  </Container>
)

export default FullPageLoader
