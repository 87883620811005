import styled from 'styled-components'

const Video = styled.video`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
`
interface VideoBackgroundProps {
  url: string
  type?: string
}
const VideoBackground = ({ url, type = 'video/mp4' }: VideoBackgroundProps) => (
  <Video
    autoPlay
    muted
    loop
    playsInline
  >
    <source
      src={url}
      type={type}
    />
  </Video>
)

export default VideoBackground
