import styled from 'styled-components'

export const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  overflow: hidden;
`
export const VideoElement = styled.video`
  min-width: 100%;
  min-height: 100%;
`

export const VideoCountDownContainer = styled.div`
  background-color: #ffffffa1;
  color: black;
  font-weight: 500;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 26px;
  line-height: 1;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 24px -1px rgb(255 255 255 / 18%);

  /**
   * I'm removing it for now, I think it's causing massive
   * performance issues.
   */
  /* @supports (backdrop-filter: blur(24px)) {
    backdrop-filter: blur(24px);
  } */

  /**
   * This styling is to prevent the countdown width from
   * jumping while counting down
   */
  > .block {
    display: inline-block;
    min-width: 40px;
    text-align: center;
  }

  @media screen and (max-width: @screen-xs) {
    padding: 12px;
    font-size: 22px;
  }

  @media screen and (min-width: @screen-4k) {
    font-size: 36px;
  }
`
export const SoundButton = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transform: translate(-50%, -50%);
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 12px 18px;
  border-radius: ${({ theme }) => theme.radius};
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  font-size: 16px;
  font-family: 'futurapt', arial, sans-serif;
  font-weight: 400;
`
export const UnmuteText = styled.span`
  color: #FFFFFF
`
