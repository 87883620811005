import { auth } from '@coding4tomorrow/c4t-next-core'
import router from 'next/router'
import { Socket } from 'socket.io-client'

const messageTypes = {
  TOKEN_INVALIDATED: 'token-invalidated',
}

class TokenSecurity {
  socket: Socket | null = null

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket.on(messageTypes.TOKEN_INVALIDATED, this.onTokenInvalidated)
  }

  unsetSocket = () => {
    this?.socket?.off(messageTypes.TOKEN_INVALIDATED, this.onTokenInvalidated)

    this.socket = null
  }

  onTokenInvalidated = () => {
    auth.clear()
    this?.socket?.disconnect()
    router.push('/invalidated')
  }
}

export default TokenSecurity
