import '~/styles/tailwind.css'
import {
  auth,
  useNextQueryParam,
  useSetup,
  useUser,
} from '@coding4tomorrow/c4t-next-core'
import { Alert } from 'antd'
import moment from 'moment'
import { NextComponentType } from 'next'
// eslint-disable-next-line no-unused-vars
import App, { AppContext } from 'next/app'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { isIE } from 'react-device-detect'
import { ThemeProvider } from 'styled-components'

import apiRoutes from '~/apiRoutes'
import config from '~/config'
import { DeviceSettingsProvider } from '~/context/deviceSettings'
import { PrePostVideoContextProvider } from '~/context/prePostVideo'
import { SocketContextProvider } from '~/context/socket'
import usePrefix from '~/hooks/usePrefix'
import useProjectInfo from '~/hooks/useProjectInfo'
import useUserClosestStage from '~/hooks/useUserClosestStage'
import useUserInteraction from '~/hooks/useUserInteraction'
import useUserStage from '~/hooks/useUserStage'

import { isConference } from '~/types/groups'
import { theme } from '~/types/themes'
import { isGuest, isViewer, UserStageType } from '~/types/user'

import MediaBackground from '~/components/app/MediaBackground'
import FullPageLoader from '@@/FullPageLoader'
import PagesTitle from '@/PagesTitle'
import RemoteInitiator from '@/RemoteInitiator'
import RemoteInterface from '@/RemoteInterface'
import VideoBackground from '@/VideoBackground'

interface PagePropsType {
  setCountDownOverStage: (value: string) => void
  groupId: string | string[] | undefined
  isSubdomain: boolean
  setIsPermissionRequested: (value: boolean) => void
  setShowPrivacyPolicy: (value: boolean) => void
  children?: React.ReactNode
}
interface CustomAppTypes {
  Component: NextComponentType
  pageProps: PagePropsType
}

/**
 * MSW implementation
 */
if (config.app.apiMocking.enabled) {
  // eslint-disable-next-line global-require
  require('~/mocks')
}

const CustomApp = ({ Component, pageProps }: CustomAppTypes): JSX.Element => {
  const router = useRouter()
  const [countDownOverStage, setCountDownOverStage] = useState('')
  const [isPermissionRequested, setIsPermissionRequested] =
    useState<boolean>(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(true)

  /**
   * TODO: Add this hook inside prePostVideo context
   */
  const { isUserInteracted } = useUserInteraction()

  const { groupId, projectId, site } = router?.query

  const isCountdownOver = groupId === countDownOverStage
  /**
   * useSetup hooks is using the useOne hook which make it setup execute
   * only one time , but since we are now relying on groupId and eventId
   * those variable might change so we will have to make setup able to get executed inside
   * a useEffect because right now it doesn't work
   *   */
  useSetup({
    api: {
      baseURL: config.api.baseURL,
      routes: apiRoutes,
    },
    auth: {
      loginPageRoute: '/unauthorized',
    },
    // I spent so much time to find a way to get the projectId from the param
    // in the guard, but I couldn't, that's why I'm sending the project id in
    // the headers (just in the case of normal use case)
    axios: {
      headers: {
        ...(projectId ? { 'X-PROJECT-ID': projectId } : {}),
        'X-STAGE-ID': groupId,
      },
    },
  })

  const { user, isLoading, loggedOut } = useUser()

  const {
    isLoading: isLoadingProjectInfo,
    nonExistentProject,
    ...projectInfos
  } = useProjectInfo()

  const userClosestStartingGroup = useUserClosestStage()

  const selectedGroupId = groupId || userClosestStartingGroup?._id

  const token = useNextQueryParam('token', router?.asPath) || auth.token

  if (token) {
    auth.token = token
  }

  const userStage = useUserStage()

  const startTime = useMemo(
    () => moment(userStage?.stage?.startDate),
    [userStage?.stage?.startDate],
  )

  const isAdmin = userStage?.isModerator
  const isEnteringEarlier = isAdmin || isGuest(userStage?.type)

  const isEarly = useMemo(
    () => moment().isBefore(startTime),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startTime, isCountdownOver],
  )

  const onCountdownReset = useCallback(() => setCountDownOverStage(''), [])

  const isSubdomain = !!site

  const PREFIX = usePrefix()

  const preEventBackground =
    userStage?.stage?.preEventBackground ||
    projectInfos?.preEventBackground ||
    config.default.preEventBackground
  const canShowPreEventBackground =
    preEventBackground &&
    (!router.asPath.includes(`${PREFIX}/event`) ||
      router.asPath.includes(`${PREFIX}/events`))
  // In here, we list all public routes that are accessible without user token requirement
  // PS: please make sure not to use `useRequiresAuth` hook while creating public pages
  const publicRoutes = [
    `${PREFIX}/unauthorized`,
    `${PREFIX}/invalidated`,
    `${PREFIX}/ticket-office-confirmation`,
    `${PREFIX}/ticket-office-confirmation?paymentStatus=success`,
    `${PREFIX}/ticket-office-confirmation?paymentStatus=error`,
    `${PREFIX}/send-for-token-confirmation`,
    `${PREFIX}/denied`,
    `${PREFIX}/event-ended`,
    `${PREFIX}/event-not-published`,
    `${PREFIX}/banned`,
    `${PREFIX}/`,
    `${PREFIX}`,
  ]

  const routes = [
    {
      condition: (!isSubdomain && !projectId) || nonExistentProject,
      routeTo: '/unauthorized',
    },
    {
      condition: (loggedOut || !user) && !publicRoutes.includes(router.asPath),
      routeTo: `${PREFIX}/denied`,
    },
    {
      // eslint-disable-next-line max-len
      condition:
        selectedGroupId &&
        !publicRoutes.includes(router.asPath) &&
        !user?.stages?.find(
          (uStage: UserStageType) => uStage.stage._id === selectedGroupId,
        ),
      routeTo: `${PREFIX}/unauthorized`,
    },
    {
      condition: !projectInfos.isActive,
      routeTo: `${PREFIX}/event-not-published`,
    },
    {
      condition: publicRoutes.includes(router.asPath),
      routeTo: null,
    },
    {
      condition: selectedGroupId && showPrivacyPolicy,
      routeTo: `${PREFIX}/privacy-policy?groupId=${selectedGroupId}`,
    },
    {
      condition: userStage?.stage?.endEvent,
      routeTo: `${PREFIX}/thank-you/${selectedGroupId}`,
    },
    {
      condition:
        isConference(userStage?.stage?.streamingType) &&
        selectedGroupId &&
        !isPermissionRequested &&
        !isViewer(user?.type) &&
        (!userStage?.stage?.endEvent ||
          (userStage?.stage?.endEvent && isAdmin)),
      routeTo: `${PREFIX}/devices-selection?groupId=${selectedGroupId}`,
    },
    {
      condition:
        selectedGroupId && isEarly && !isCountdownOver && !isEnteringEarlier,
      routeTo: `${PREFIX}/countdown?groupId=${selectedGroupId}`,
    },
    {
      condition:
        (!isEarly || isCountdownOver || isEnteringEarlier) &&
        selectedGroupId &&
        !isIE,
      routeTo: `${PREFIX}/event/${selectedGroupId}`,
    },
  ]

  const showRemoteInterface =
    user && userStage?.isModerator && config.app.remote.enabled
  // to avoid infinite loading when there is a token in the cookie
  // but the user is not registered to the ticket office event
  if (router.asPath.includes('ticket-office')) {
    auth.clear()
  }

  if ((token && isLoading) || isLoadingProjectInfo) {
    return <FullPageLoader />
  }

  // We are passing the function that sets the state of the countdown (is the time of the event is
  // reached or not) to countdown container, in order to set isEarly to false when the countdown
  // finishes, and then redirect the user to event page
  // eslint-disable-next-line no-param-reassign
  pageProps.setCountDownOverStage = setCountDownOverStage
  // eslint-disable-next-line no-param-reassign
  pageProps.groupId = groupId

  // eslint-disable-next-line no-param-reassign
  pageProps.isSubdomain = isSubdomain

  if (router.asPath.includes(`${PREFIX}/devices-selection`)) {
    // eslint-disable-next-line no-param-reassign
    pageProps.setIsPermissionRequested = setIsPermissionRequested
  }

  if (router.asPath.includes(`${PREFIX}/privacy-policy`)) {
    // eslint-disable-next-line no-param-reassign
    pageProps.setShowPrivacyPolicy = setShowPrivacyPolicy
  }
  //

  // eslint-disable-next-line no-restricted-syntax
  for (const route of routes) {
    const { condition, routeTo } = route

    if (process.browser && condition) {
      if (
        routeTo === null ||
        router.asPath === routeTo ||
        /**
         * this condition is added here to make all
         * the ticket-office page public
         */
        (router.asPath.includes('ticket-office') &&
          projectInfos.type === 'public') ||
        router.asPath.includes('thank-you')
      ) {
        break
      }

      router.replace(routeTo)

      /**
       * Fix context reset, now the context
       * are kept intact and not reinitialised
       * between pages.
       */
      return (
        <>
          <PagesTitle />
          {canShowPreEventBackground && (
            <VideoBackground url={preEventBackground} />
          )}
          <ThemeProvider theme={theme.newTicketOffice}>
            <SocketContextProvider token={token}>
              <DeviceSettingsProvider>
                <PrePostVideoContextProvider
                  group={userStage?.stage}
                  isAdmin={isAdmin}
                  isUserInteracted={isUserInteracted}
                >
                  {/* this is a dirty hack to make the antd Alert component get displayed */}
                  {false && <Alert message="" />}
                  <FullPageLoader />
                  {showRemoteInterface && (
                    <RemoteInterface onCountdownReset={onCountdownReset} />
                  )}
                  {!userStage?.isModerator && (
                    <RemoteInitiator onCountdownReset={onCountdownReset} />
                  )}
                </PrePostVideoContextProvider>
              </DeviceSettingsProvider>
            </SocketContextProvider>
          </ThemeProvider>
        </>
      )
    }
  }

  return (
    <>
      <PagesTitle />
      {canShowPreEventBackground && (
        <MediaBackground url={preEventBackground} />
      )}
      <ThemeProvider theme={theme.lacoste}>
        <SocketContextProvider token={token}>
          <DeviceSettingsProvider>
            <PrePostVideoContextProvider
              group={userStage?.stage}
              isAdmin={isAdmin}
              isUserInteracted={isUserInteracted}
            >
              {/* this is a dirty hack to make the antd Alert component get displayed */}
              {false && <Alert message="" />}
              <Component {...pageProps} />
              {showRemoteInterface && (
                <RemoteInterface onCountdownReset={onCountdownReset} />
              )}
              {!userStage?.isModerator && (
                <RemoteInitiator onCountdownReset={onCountdownReset} />
              )}
            </PrePostVideoContextProvider>
          </DeviceSettingsProvider>
        </SocketContextProvider>
      </ThemeProvider>
    </>
  )
}

CustomApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)

  return { ...appProps }
}

export default CustomApp
