import { Socket } from 'socket.io-client'

export const messageTypes = {
  END_STREAM: 'end-stream',
}

class EndStream {
  socket: Socket | null = null

  callback: any = null

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket?.on(messageTypes.END_STREAM, this.onEndStream)
  }

  unsetSocket = () => {
    this.socket?.off(messageTypes.END_STREAM, this.onEndStream)

    this.socket = null
  }

  handleEndStream(groupId: string, connectionsIds?: string[]) {
    this.socket?.emit(messageTypes.END_STREAM, {
      groupId,
      connectionsIds,
    })
  }

  setEndStreamCallback = (callback: any) => {
    this.callback = callback
  }

  onEndStream = () => {
    this.callback?.()
  }
}

export default EndStream
