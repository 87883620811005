import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useDevices } from '~/hooks/useDevices'

import { isAudioInput, isVideoInput } from '~/types/devices'
import { PermissionState } from '~/types/permission'

type DeviceSettingsReturnType = {
  audioInput: string | null
  videoInput: string | null
  previewdVideoInput: string | null
  setCurrentAudioInput: ((deviceId: string) => void) | null
  setCurrentVideoInput: ((deviceId: string) => void) | null
  setPreviewdVideoInput: ((deviceId: string) => void) | null
  audioInputDevices: any[]
  videoDevices: any[]
  permissionState: PermissionState
  askUserForPermission: (() => void) | null
}

const DeviceSettingsContextDefaultValues = {
  audioInput: null,
  videoInput: null,
  previewdVideoInput: null,
  setCurrentAudioInput: null,
  setCurrentVideoInput: null,
  setPreviewdVideoInput: null,
  audioInputDevices: [],
  videoDevices: [],
  permissionState: PermissionState.MUST_ASK,
  askUserForPermission: null,
}
const DeviceSettingsContext = createContext(DeviceSettingsContextDefaultValues)
const { Provider } = DeviceSettingsContext

const DeviceSettings = (): DeviceSettingsReturnType => {
  const [audioInput, setAudioInput] = useState<string | null>(null)
  const [videoInput, setVideoInput] = useState<string | null>(null)
  const [previewdVideoInput, setPreviewdVideoInput] = useState<any>(null)
  const [permissionState, permissionStateSet] = useState(
    PermissionState.MUST_ASK,
  )
  const [devices, setDevices] = useState<any[]>([])

  const { askForCameraAndMicPermission, getDevices } = useDevices()

  const setCurrentAudioInput = useCallback((audioDeviceId: string) => {
    localStorage.setItem('audioInput', audioDeviceId)
    setAudioInput(audioDeviceId)
  }, [])

  const setCurrentVideoInput = useCallback((videoDeviceId: string) => {
    localStorage.setItem('videoInput', videoDeviceId)
    setVideoInput(videoDeviceId)
  }, [])

  const askUserForPermission = useCallback(() => {
    askForCameraAndMicPermission().then((state: any) => {
      permissionStateSet(state)

      if (state) {
        getDevices().then((avialableDevices: any[]) => {
          setDevices(avialableDevices)
        })
      }
    })
  }, [askForCameraAndMicPermission, getDevices])

  const videoDevices = useMemo(
    () => devices?.filter((device) => isVideoInput(device.kind)),
    [devices],
  )

  const audioInputDevices = useMemo(
    () => devices?.filter((device) => isAudioInput(device.kind)),
    [devices],
  )

  useEffect(() => {
    if (audioInputDevices.length === 0) {
      return
    }

    const defaultAudio = audioInputDevices[0]?.deviceId

    if (!defaultAudio) {
      return
    }

    const storedAudioInput = localStorage.getItem('audioInput')

    if (!storedAudioInput) {
      setAudioInput(defaultAudio)
      localStorage.setItem('audioInput', defaultAudio)
    } else {
      // check if the audioInput exists in the avialable devices
      const isDeviceExists = audioInputDevices.find(
        (device) => device.deviceId === storedAudioInput,
      )

      if (isDeviceExists) {
        setAudioInput(storedAudioInput)
      } else if (defaultAudio) {
        setAudioInput(defaultAudio)
        localStorage.setItem('audioInput', defaultAudio)
      }
    }
  }, [audioInputDevices])

  useEffect(() => {
    if (videoDevices.length === 0) {
      return
    }

    const defaultVideo = videoDevices[0]?.deviceId

    if (!defaultVideo) {
      return
    }

    const storedVideoInput = localStorage.getItem('videoInput')

    if (!storedVideoInput) {
      setVideoInput(defaultVideo)
      setPreviewdVideoInput(defaultVideo)
      localStorage.setItem('videoInput', defaultVideo)
    } else {
      // check if the videoInput exists in the avialable devices
      const isDeviceExists = videoDevices.find(
        (device) => device.deviceId === storedVideoInput,
      )

      if (isDeviceExists) {
        setVideoInput(storedVideoInput)
        setPreviewdVideoInput(storedVideoInput)
      } else {
        setVideoInput(defaultVideo)
        setPreviewdVideoInput(defaultVideo)
        localStorage.setItem('videoInput', defaultVideo)
      }
    }
  }, [videoDevices])

  return {
    audioInput,
    videoInput,
    previewdVideoInput,
    setCurrentAudioInput,
    setCurrentVideoInput,
    setPreviewdVideoInput,
    audioInputDevices,
    videoDevices,
    permissionState,
    askUserForPermission,
  }
}

export const useDeviceSettingsContext = (): DeviceSettingsReturnType =>
  useContext(DeviceSettingsContext)

export const DeviceSettingsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => <Provider value={DeviceSettings() as any}>{children}</Provider>
