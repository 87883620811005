import Head from 'next/head'

import useProjectInfo from '~/hooks/useProjectInfo'
import useUserStage from '~/hooks/useUserStage'

const StageTitle = () => {
  const { stage } = useUserStage() || {}
  const { name: stageName, description } = stage || {}
  const { name: projectName } = useProjectInfo() || {}

  return (
    <Head>
      <meta
        name="description"
        content={description}
      />
      <title>{`${stageName} | ${projectName} | Gleamer`}</title>
    </Head>
  )
}

export default StageTitle
