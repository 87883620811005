export enum DeviceKindes {
  AUDIO_INPUT = 'audioinput',
  AUDIO_OUTPUT = 'audiooutput',
  VIDEO_INPUT = 'videoinput',
}

export const isVideoInput = (kind: DeviceKindes) =>
  kind === DeviceKindes.VIDEO_INPUT
export const isAudioInput = (kind: DeviceKindes) =>
  kind === DeviceKindes.AUDIO_INPUT
