import { useUser } from '@coding4tomorrow/c4t-next-core'
import moment from 'moment'
import { useMemo } from 'react'

import { UserStageType } from '~/types/user'

import { StageType } from './useUserStage'

const useUserClosestStage = (): StageType => {
  const { user } = useUser()

  const filteredStages = useMemo(
    () =>
      user?.stages?.sort(
        (a: UserStageType, b: UserStageType) =>
          (moment(a.stage.startDate).toDate() as any) -
          (moment(b.stage.startDate).toDate() as any),
      ),
    [user],
  )
  return filteredStages?.[0]?.stage
}

export default useUserClosestStage
