import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

import { InteractionsTypes } from '~/types/interactions'

export const messageTypes = {
  SET_INTERACTION: 'set-interaction',
  GET_INTERACTION: 'get-interaction',
  INTERACTION_RECEIVED: 'interaction-received',
}

class Interactions extends DeferredInitialisation {
  socket: Socket | null = null

  onInteractionReceivedCallback: (
    interaction: InteractionsTypes | null,
  ) => void = () => {}

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket.on(
      messageTypes.INTERACTION_RECEIVED,
      this.onInteractionReceived,
    )

    this.setInitialised(this)
  }

  unsetSocket = () => {
    this?.socket?.off(
      messageTypes.INTERACTION_RECEIVED,
      this.onInteractionReceived,
    )

    this.socket = null
  }

  setInteraction(interaction: string, stageId: string) {
    this.socket?.emit(messageTypes.SET_INTERACTION, { interaction, stageId })
  }

  getInteraction(stageId: string) {
    return this.socket?.emit(messageTypes.GET_INTERACTION, { stageId })
  }

  onInteractionReceived = (interaction: InteractionsTypes | null) => {
    this.onInteractionReceivedCallback(interaction)
  }
}

export default Interactions
