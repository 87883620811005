import { useUser } from '@coding4tomorrow/c4t-next-core'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useSocketContext } from '~/context/socket'

import { User } from '~/types/user'

interface RemoteUser {
  user: User
  currentLocation: string
}
interface UseRemoteType {
  users: RemoteUser[]
}
const useRemote = (onCountdownReset: () => void): UseRemoteType => {
  const [users, usersSet] = useState([])
  const { remote } = useSocketContext()
  const { mutate } = useUser()
  const { pathname, components }: any = useRouter()
  const currentLocation = components?.[pathname]?.Component?.name

  useEffect(() => {
    if (!currentLocation || !remote) {
      return
    }

    remote.isReady(() => {
      remote.onUsersUpdateCallback = usersSet
      remote.onCountdownResetedCallback = () => {
        onCountdownReset()
        mutate()
      }
      remote.onCountdownSpeededUpCallback = () => mutate()
      remote.joinPage(currentLocation)
    })

    // eslint-disable-next-line no-useless-return
    return
  }, [currentLocation, remote, mutate])

  return {
    users,
  }
}

export default useRemote
