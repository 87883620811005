import { Socket } from 'socket.io-client'

import { Product } from '~/domains/Shopping/types/Product'
import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  REVEAL_PRODUCT: 'reveal-product',
  REFRESH_PRODUCTS: 'refresh-product',
  GET_REVEALED_PRODUCTS: 'get-revealed-products',
  RESET_PUSHED_PRODUCTS: 'reset-pushed-products',
  REMOVE_PRODUCT: 'remove-product',
  PRODUCT_REMOVED: 'product-removed',
  PRODUCT_REFUNDED: 'product-refunded',
}
export enum RefreshActionTypes {
  ADD = 'add',
  DELETE = 'delete',
  UPDATE = 'update',
}

class RevealProduct extends DeferredInitialisation {
  socket: Socket | null = null

  onRevealProductCallback: (products: Product[]) => void = () => {}

  onGetRevealedProductsCallback: (products: Product[]) => void = () => {}

  onProductRefundedCallback: (product: Product) => void = () => {}

  onRefreshProductsCallback: (
    product: Product,
    type: RefreshActionTypes,
  ) => void = () => {}

  onProductRemovedCallback: (productId: string) => void = () => {}

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket?.on(messageTypes.REVEAL_PRODUCT, this.onRevealProduct)
    this.socket?.on(
      messageTypes.GET_REVEALED_PRODUCTS,
      this.onGetRevealedProducts,
    )
    this.socket?.on(
      messageTypes.RESET_PUSHED_PRODUCTS,
      this.onGetRevealedProducts,
    )
    this.socket.on(messageTypes.REFRESH_PRODUCTS, this.onRefreshProducts)
    this.socket.on(messageTypes.PRODUCT_REMOVED, this.onProductRemoved)
    this.socket.on(messageTypes.PRODUCT_REFUNDED, this.OnProductRefunded)

    this.setInitialised(this)
  }

  unsetSocket = () => {
    this.socket?.off(messageTypes.REVEAL_PRODUCT, this.onRevealProduct)
    this.socket?.off(
      messageTypes.GET_REVEALED_PRODUCTS,
      this.onGetRevealedProducts,
    )
    this.socket?.off(
      messageTypes.RESET_PUSHED_PRODUCTS,
      this.onGetRevealedProducts,
    )
    this?.socket?.off(messageTypes.REFRESH_PRODUCTS, this.onRefreshProducts)
    this?.socket?.off(messageTypes.PRODUCT_REMOVED, this.onProductRemoved)
    this?.socket?.off(messageTypes.PRODUCT_REFUNDED, this.OnProductRefunded)

    this.socket = null
  }

  handleRevealProduct(product: Product, groupId: string) {
    this.socket?.emit(messageTypes.REVEAL_PRODUCT, {
      product,
      groupId,
    })
  }

  handleResetPushedProducts(groupId: string) {
    this.socket?.emit(messageTypes.RESET_PUSHED_PRODUCTS, {
      groupId,
    })
  }

  handleGetRevealedProducts(groupId: string) {
    this.socket?.emit(messageTypes.GET_REVEALED_PRODUCTS, { groupId })
  }

  onRevealProduct = (products: Product[]) => {
    this.onRevealProductCallback(products)
  }

  onGetRevealedProducts = (products: Product[]) => {
    this.onGetRevealedProductsCallback(products)
  }

  onRefreshProducts = (data: {
    product: Product
    type: RefreshActionTypes
  }) => {
    this.onRefreshProductsCallback(data.product, data.type)
  }

  removeProduct = (productId: string, groupId: string) => {
    this?.socket?.emit(messageTypes.REMOVE_PRODUCT, { productId, groupId })
  }

  onProductRemoved = (productId: string) => {
    this.onProductRemovedCallback(productId)
  }

  OnProductRefunded = (product: Product) => {
    this.onProductRefundedCallback(product)
  }
}

export default RevealProduct
