export const projectPublicInfoStub: any = {
  isClosed: false,
  closestStartingEvent: '62b02157682a391cc05634af',
  projectType: 'private',
  isActive: true,
}

export const paymentIntentStub: any = {
  id: 'fake-id',
  clientSecret: 'fake-secret',
}
