import Head from 'next/head'

import useProjectInfo from '~/hooks/useProjectInfo'

const EventTitle = () => {
  const { name, description } = useProjectInfo() || {}

  return (
    <Head>
      <meta
        name="description"
        content={description}
      />
      <title>{name} | Gleamer</title>
    </Head>
  )
}

export default EventTitle
