import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  JOIN_VIEWERS_ROOM: 'join-viewers-room',
  NEW_VIEWER_JOINED: 'new-viewer-joined',
  LEAVE_VIEWERS_ROOM: 'leave-viewers-room',
  VIEWER_LEFT: 'viewer-left',
}

class ViewersRoom extends DeferredInitialisation {
  socket: Socket | null = null

  onViewersUpdatedCallback: any = null

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket.on(messageTypes.NEW_VIEWER_JOINED, this.onViewersUpdated)
    this.socket.on(messageTypes.VIEWER_LEFT, this.onViewersUpdated)

    // Check DeferredInitialisation for more details.
    this.setInitialised(this)
  }

  unsetSocket = () => {
    this?.socket?.off(messageTypes.NEW_VIEWER_JOINED, this.onViewersUpdated)
    this?.socket?.off(messageTypes.VIEWER_LEFT, this.onViewersUpdated)

    this.socket = null
  }

  onViewersUpdated = (list: any) => {
    this.onViewersUpdatedCallback?.(list)
  }

  setViewersUpdateCallback = (cb: any) => {
    this.onViewersUpdatedCallback = cb
  }

  joinViewersRoom(groupId: string) {
    this.socket?.emit(messageTypes.JOIN_VIEWERS_ROOM, { groupId })
  }

  leaveViewersRoom(groupId: string) {
    this.socket?.emit(messageTypes.LEAVE_VIEWERS_ROOM, { groupId })
  }
}

export default ViewersRoom
