import React from 'react'
import styled from 'styled-components'

import VideoBackground from '../VideoBackground'

interface MediaBackgroundProps {
  url: string
  type?: string
}

const ImageBackground = styled.img`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 0;
`

const isUrlAnImage = (url: string) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg']
  const urlExtension = url.split('.').pop()?.toLowerCase()
  return imageExtensions.includes(urlExtension as string)
}

const MediaBackground = ({ url, type }: MediaBackgroundProps) => {
  if (isUrlAnImage(url)) {
    return (
      <ImageBackground
        src={url}
        alt="Background"
      />
    )
  }

  return (
    <VideoBackground
      url={url}
      type={type}
    />
  )
}

export default MediaBackground
