import { useCallback, useEffect, useState } from 'react'

const useUserInteraction = () => {
  const [isUserInteracted, isUserInteractedSet] = useState(false)
  const handleUserInteraction = useCallback(() => {
    isUserInteractedSet(true)
    document.body.removeEventListener('scroll', handleUserInteraction)
    document.body.removeEventListener('keydown', handleUserInteraction)
    document.body.removeEventListener('click', handleUserInteraction)
    document.body.removeEventListener('touchstart', handleUserInteraction)
  }, [])
  const detectUserInteraction = useCallback(
    () => {
      document.body.addEventListener('scroll', handleUserInteraction)
      document.body.addEventListener('keydown', handleUserInteraction)
      document.body.addEventListener('click', handleUserInteraction)
      document.body.addEventListener('touchstart', handleUserInteraction)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(
    () => {
      detectUserInteraction?.()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    isUserInteracted,
  }
}

export default useUserInteraction
