import { StageType } from './groups'

export enum UserTypes {
  BROADCASTER = 'broadcaster',
  VIEWER = 'viewer',
  PARTICIPANT = 'participant',
  GUEST = 'guest',
}

export interface UserStageType {
  stage: StageType
  type: UserTypes
  isModerator: boolean
}

export const isBroadcaster = (userType: UserTypes) =>
  userType === UserTypes.BROADCASTER
export const isViewer = (userType: UserTypes) => userType === UserTypes.VIEWER
export const isParticipant = (userType: UserTypes) =>
  userType === UserTypes.PARTICIPANT
export const isGuest = (userType: UserTypes) => userType === UserTypes.GUEST

export enum UserRoles {
  USER = 'user',
  MODERATOR = 'moderator',
  PROJECT_ADMIN = 'project-admin',
  SUPER_ADMIN = 'super-admin',
}

export const isUser = (userRole: UserRoles) => userRole === UserRoles.USER
export const isModeratore = (userRole: UserRoles) =>
  userRole === UserRoles.MODERATOR
export const isProjectAdmin = (userRole: UserRoles) =>
  userRole === UserRoles.PROJECT_ADMIN
export const isSuperAdmin = (userRole: UserRoles) =>
  userRole === UserRoles.SUPER_ADMIN
export const isSuperiorRole = (userRole: UserRoles) =>
  [
    UserRoles.MODERATOR,
    UserRoles.PROJECT_ADMIN,
    UserRoles.SUPER_ADMIN,
  ].includes(userRole)

export interface User {
  id: string
  _id: string
  firstName: string
  lastName: string
  nickname: string
  code: string
  email: string
  authToken: string
  region: string
  cookiesConsent?: boolean
  agreedRules?: boolean
  groups: StageType[]
  type: string
  isDeleted: boolean
  registrationType: string
  stages: UserStageType[]
  isActive: boolean
}
