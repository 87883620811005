interface MethodsType {
  [key: string]: (routePath: string) => void
}

const apiRoutes = ({ get, post, put, del }: MethodsType) => ({
  users: {
    checkProjectType: get('/v1/users/check-project-type'),
    me: get('/v1/users/me'),
    regenerateToken: post('/v1/users/regenerate-token'),
    cookiesConsent: post('/v1/users/cookies-consent'),
    agreedRules: post('/v1/users/agreed-rules'),
    generateOTToken: post('/v1/users/generate-ot-token'),
    chat: {
      deleteMessage: del('/v1/users/delete-message'),
      updateMessage: put('/v1/users/update-message'),
    },
    ban: post('/v1/users/:id/ban'),
    getCountryCode: get('/v1/users/country-code'),
    getStages: get('/v1/users/stages'),
  },
  project: {
    getProjectPublicInfos: get('/v1/projects/public-info'),
    registerUser: post('/v1/project/:projectId/ticket-office/create'),
    handleUserStatus: post(
      '/v1/project/:projectId/ticket-office/handle-user-status',
    ),
    createProjectPaymentIntent: post(
      '/v1/project/:projectId/ticket-office/payment-intent',
    ),
  },
  group: {
    speedUpCountdown: post('/v1/group/:groupId/speedUpCountdown'),
    resetCountdown: post('/v1/group/:groupId/resetCountdown'),
  },
  gumroad: {
    getAllProducts: get('/v1/gumroad/products'),
  },
  products: {
    getAll: get('/v1/products/stage/:id'),
    getOne: get('/v1/products/:id'),
    createPaymentIntent: post('/v1/products/:id/payment-intent'),
    savePaymentInfo: post('/v1/products/:id/save-payment-info'),
  },
})

export default apiRoutes
