import { Socket } from 'socket.io-client'

import DeferredInitialisation from '~/lib/deferred-initialisation'

export const messageTypes = {
  FREE_TRIAL_ENDED: 'free-trial-ended',
}

class Plans extends DeferredInitialisation {
  socket: Socket | null = null

  freeTrialEndedCallback: () => void = () => {}

  setSocket = (socket: Socket) => {
    this.socket = socket

    this.socket?.on(messageTypes.FREE_TRIAL_ENDED, this.onFreeTrialEnded)
    this.setInitialised(this)
  }

  unsetSocket = () => {
    this.socket?.off(messageTypes.FREE_TRIAL_ENDED, this.onFreeTrialEnded)
    this.socket = null
  }

  onFreeTrialEnded = () => {
    this.freeTrialEndedCallback()
  }
}

export default Plans
