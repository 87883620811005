import { useRouter } from 'next/router'

const usePrefix = (): string => {
  const router = useRouter()
  const { site, projectId } = router?.query
  const isSubdomain = !!site

  return isSubdomain ? '' : `/project/${projectId}`
}

export default usePrefix
