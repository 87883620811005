export const parseBoolean = (value: any, defaultValue = false): boolean => {
  if (value === undefined || value === null) {
    return defaultValue
  }

  return !/^(false|0)$/i.test(value) && !!value
}

const parseInteger = (value: any): number => parseInt(value, 10)

const config = {
  app: {
    apiMocking: {
      enabled: parseBoolean(process.env.NEXT_PUBLIC_API_MOCKING_ENABLED, false),
    },
    testingTool: {
      enabled: parseBoolean(process.env.NEXT_PUBLIC_APP_TESTING_TOOL_ENABLED),
    },
    remote: {
      enabled: parseBoolean(
        process.env.NEXT_PUBLIC_APP_DEMO_REMOTE_TOOL_ENABLED,
      ),
    },
  },
  api: {
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL as string,
  },
  sendbird: {
    appId: process.env.NEXT_PUBLIC_SENDBIRD_APP_ID as string,
  },
  events: {
    url: process.env.NEXT_PUBLIC_EVENTS_URL as string,
  },
  stripe: {
    apiKey: process.env.NEXT_PUBLIC_STRIPE_API_KEY as string,
  },
  raiseHand: {
    timeOut: process.env.NEXT_PUBLIC_RAISE_HAND_TIMEOUT || 40000,
  },
  privacy: {
    link: process.env.NEXT_PUBLIC_PRIVACY_LINK as string,
    termsOfUse: process.env.NEXT_PUBLIC_TERMS_OF_USE as string,
  },
  conference: {
    maxParticipants:
      (parseInteger(process.env.NEXT_PUBLIC_MAX_PARTICIPANTS) as number) || 20,
  },
  shopping: {
    autoFill: parseBoolean(process.env.NEXT_PUBLIC_SHOPPING_AUTO_FILL_ENABLED),
  },
  interactions: {
    poll: process.env.NEXT_PUBLIC_EMBED_STRAWPOLL_URL,
    typeform: process.env.NEXT_PUBLIC_EMBED_TYPEFORM_URL,
    enabled: parseBoolean(process.env.NEXT_PUBLIC_INTERACTIONS_ENABLED),
  },
  openTok: {
    apiKey: process.env.NEXT_PUBLIC_OPENTOK_API_KEY || '',
  },
  default: {
    logo:
      process.env.NEXT_PUBLIC_DEFAULT_LOGO ||
      'https://gleamer-default.s3.eu-west-3.amazonaws.com/logo-gleamer-gris.svg',
    preEventBackground: process.env.NEXT_PUBLIC_DEFAULT_PRE_EVENT_BACKGROUND,
  },
  development: parseBoolean(process.env.NEXT_PUBLIC_DEVELOPMENT),
  gleamer: {
    websiteUrl: process.env.NEXT_PUBLIC_GLEAMER_WEBSITE_URL,
  },
}

export default config
