// import { useApi } from '@coding4tomorrow/c4t-next-core'
import { Button, notification, Space } from 'antd'
import { useCallback /* useEffect */ } from 'react'
import styled from 'styled-components'

import { useSocketContext } from '~/context/socket'
import useRemote from '~/hooks/useRemote'
import useUserStage from '~/hooks/useUserStage'

import { UserStageType } from '~/types/user'

import P from '@@/P'

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 12px;
  background-color: rgba(0, 0, 0, .7);
  color: @white;
  min-width: 300px;
  z-index: 1000;
`

type RemoteInitiatorProps = {
  onCountdownReset: () => void
}

const RemoteInterface = ({ onCountdownReset }: RemoteInitiatorProps) => {
  const {
    stage: { _id: groupId },
  }: UserStageType = useUserStage()
  const { users } = useRemote(onCountdownReset)
  const { remote } = useSocketContext()

  /* const { request: resetCountdownRequest, loading: resettingCountdown } =
    useApi('group.resetCountdown', {
      groupId,
    }) */

  const handleSpeedUpCountdown = useCallback(() => {
    remote?.speedUpCountdown(groupId)

    notification.open({
      description: 'Countdown has been speeded up',
      duration: 5,
      message: '',
    })
  }, [remote, groupId])

  const handleResetCountdown = useCallback(() => {
    remote?.resetCountdown(groupId)

    notification.open({
      description: 'Countdown has been reset',
      duration: 5,
      message: '',
    })
  }, [remote, groupId])

  return (
    <Container>
      <Space direction="vertical">
        <P>Demo remote</P>
        <Space>
          <Button onClick={handleSpeedUpCountdown}>Speed up countdown</Button>
          <Button onClick={handleResetCountdown}>Reset countdown</Button>
        </Space>
        {users.map((user) => (
          <P key={user?.user?.id}>
            {user?.user?.firstName} {user?.user?.lastName} (
            {user.currentLocation})
          </P>
        ))}
      </Space>
    </Container>
  )
}

export default RemoteInterface
