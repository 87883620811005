// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw'

import { userStub } from '../users/stubs'
import { api } from '../utils'
import { paymentIntentStub, projectPublicInfoStub } from './stubs'

const handlers = [
  rest.get(api('/v1/projects/public-info'), (_req, res, ctx) => {
    const projectId = _req.headers.get('x-project-id') as string
    if (projectId?.includes('fake')) {
      return res(ctx.status(404))
    }
    return res(ctx.status(200), ctx.json(projectPublicInfoStub))
  }),
  rest.post(
    api('/v1/projects/public-info/:projectId/ticket-office/create'),
    (_req, res, ctx) => res(ctx.status(200), ctx.json(userStub)),
  ),
  rest.post(
    api('/v1/projects/public-info/:projectId/ticket-office/handle-user-status'),
    (_req, res, ctx) => res(ctx.status(200), ctx.json(userStub)),
  ),
  rest.post(
    api('/v1/projects/public-info/:projectId/ticket-office/payment-intent'),
    (_req, res, ctx) => res(ctx.status(200), ctx.json(paymentIntentStub)),
  ),
]

export default handlers
