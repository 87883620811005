import useRemote from '~/hooks/useRemote'

type RemoteInitiatorProps = {
  onCountdownReset: () => void
}

const RemoteInitiator = ({ onCountdownReset }: RemoteInitiatorProps) => {
  useRemote(onCountdownReset)

  return <></>
}

export default RemoteInitiator
