export enum GroupTypes {
  conference = 'conference',
  oneToManyEmbed = 'one-to-many-embed',
}

export const isConference = (groupType: GroupTypes) =>
  groupType === GroupTypes.conference
export const isOneToManyEmbed = (groupType: GroupTypes) =>
  groupType === GroupTypes.oneToManyEmbed

export interface StageType {
  _id: string
  // eslint-disable-next-line no-restricted-globals
  name: string
  opentokSessionId: string
  sendbirdRoomId: string
  startDate: Date
  endDate: Date
  project: string
  oneToManyEmbed?: Record<string, unknown>
  streamingType: GroupTypes
  preVideoUrl: string
  preVideoDuration: number
  postVideoUrl: string
  streamingBackgroundUrl: string
  preEventBackground: string
  endEvent?: boolean
  endedBy?: string
  description?: string
  pinwideStream: string
  spotlightStreams: string[]
  isRaiseHandEnabled: boolean
  duration: string
  coverImage?: string
  oneToManyStreamUrl: string
  isDeleted: boolean
}
