export enum PermissionState {
  GRANTED = 'granted',
  REFUSED = 'refused',
  MUST_ASK = 'must-ask',
  ALREADY_IN_USE = 'already-in-use',
}

export const isPermissionStateGranted = (state: PermissionState) =>
  state === PermissionState.GRANTED
export const isPermissionStateRefused = (state: PermissionState) =>
  state === PermissionState.REFUSED
export const isPermissionStateMustAsk = (state: PermissionState) =>
  state === PermissionState.MUST_ASK
export const isPermissionStateAlreadyInUse = (state: PermissionState) =>
  state === PermissionState.ALREADY_IN_USE
