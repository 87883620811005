import { useUser } from '@coding4tomorrow/c4t-next-core'
import { useRouter } from 'next/router'

import { GroupTypes } from '~/types/groups'
import { UserStageType } from '~/types/user'

export interface StageType {
  _id: string
  // eslint-disable-next-line no-restricted-globals
  name: string
  opentokSessionId: string
  sendbirdRoomId: string
  startDate: Date
  endDate: Date
  project: string
  oneToManyEmbed?: Record<string, unknown>
  streamingType: GroupTypes
  preVideoUrl: string
  preVideoDuration: number
  postVideoUrl: string
  streamingBackgroundUrl: string
  preEventBackground: string
  endEvent?: boolean
  endedBy?: string
  description?: string
  pinwideStream: string
  spotlightStreams: string[]
  isRaiseHandEnabled: boolean
  duration: string
}

const useUserStage = (): UserStageType => {
  const router = useRouter()
  const { user } = useUser()

  const { groupId } = router?.query

  const userStage = !groupId
    ? user?.stages?.[0]
    : user?.stages?.find((stage: UserStageType) => stage.stage._id === groupId)

  return userStage || {}
}

export default useUserStage
