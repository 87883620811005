import Head from 'next/head'
import { useRouter } from 'next/router'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import usePrefix from '~/hooks/usePrefix'
import useProjectInfo from '~/hooks/useProjectInfo'
import useUserStage from '~/hooks/useUserStage'

import { StageType } from '~/types/groups'
import { isParticipant } from '~/types/user'

import VideoScene from './components/VideoScene'

const PrePostVideoContext = createContext({})
const { Provider } = PrePostVideoContext

export const usePrePostVideoContext = (): PrePostVideoContextReturnType =>
  useContext(PrePostVideoContext) as PrePostVideoContextReturnType

interface PrePostVideoContextProviderProps {
  children: React.ReactNode
  group: StageType
  isAdmin?: boolean
  isUserInteracted?: boolean
}
interface PrePostVideoContextReturnType {
  isPreVideoEnabled: boolean
  startPreVideo: () => void
  startPostVideo: () => void
  canShowContent: boolean
  isUserInteracted: boolean
}
export const PrePostVideoContextProvider = ({
  children,
  group,
  isAdmin = false,
  isUserInteracted,
}: PrePostVideoContextProviderProps) => {
  const [showPreVideo, showPreVideoSet] = useState(false)
  const [showPostVideo, showPostVideoSet] = useState(false)
  const [canShowContent, canShowContentSet] = useState(false)
  const router = useRouter()
  const PREFIX = usePrefix()
  const { mutateProjectInfos } = useProjectInfo()
  const userStage = useUserStage()

  const isPreVideoSeen = useMemo(() => {
    if (!group) {
      return false
    }

    return !!localStorage.getItem(`pre_video_${group._id}`)
  }, [group])

  const isPreVideoEnabled =
    group?.preVideoUrl &&
    !isAdmin &&
    !isPreVideoSeen &&
    !group?.endEvent &&
    isParticipant(userStage.type)
  const isPostVideoEnabled =
    group?.postVideoUrl && !isAdmin && isParticipant(userStage.type)

  const onPreVideoStart = useCallback(() => {
    if (!group) {
      return
    }

    localStorage.setItem(`pre_video_${group?._id}`, '1')
  }, [group])

  const onPreVideoEnd = useCallback(() => {
    showPreVideoSet(false)
    canShowContentSet(true)
  }, [])

  const onPostVideoEnd = useCallback(() => {
    showPostVideoSet(false)
    canShowContentSet(true)
    router.push(`${PREFIX}/thank-you/${group?._id}`)
  }, [PREFIX, group?._id, router])

  const startPreVideo = useCallback(() => {
    if (!group) {
      return
    }

    if (isPreVideoEnabled) {
      showPreVideoSet(true)
    } else {
      canShowContentSet(true)
    }
  }, [group, isPreVideoEnabled])

  const startPostVideo = useCallback(() => {
    if (!group) {
      return
    }

    mutateProjectInfos()

    if (!group?.postVideoUrl && !isAdmin) {
      router.push(`${PREFIX}/thank-you/${group._id}`)
    } else if (isPostVideoEnabled) {
      canShowContentSet(false)
      showPostVideoSet(true)
    }
  }, [group, PREFIX, isAdmin, isPostVideoEnabled, router, mutateProjectInfos])

  return (
    <Provider
      value={{
        isPreVideoEnabled,
        startPreVideo,
        startPostVideo,
        canShowContent,
        isUserInteracted,
      }}
    >
      <>
        <Head>
          {isPreVideoEnabled && (
            <link
              rel="preload"
              as="video"
              href={group.preVideoUrl}
              key={group.preVideoUrl}
            />
          )}
          {isPostVideoEnabled && (
            <link
              rel="preload"
              as="video"
              href={group.postVideoUrl}
              key={group.postVideoUrl}
            />
          )}
        </Head>
        {isPreVideoEnabled && showPreVideo && (
          <VideoScene
            onStart={onPreVideoStart}
            onEnd={onPreVideoEnd}
            videoUrl={group.preVideoUrl}
            videoDuration={group.preVideoDuration}
            isUserInteracted={isUserInteracted}
          />
        )}
        {isPostVideoEnabled && showPostVideo && (
          <VideoScene
            onEnd={onPostVideoEnd}
            isUserInteracted={isUserInteracted}
            videoUrl={group.postVideoUrl}
          />
        )}
        {children}
      </>
    </Provider>
  )
}
